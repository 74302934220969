import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Report from "./pages/Report";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route element={<PrivateRoutes />}>
        <Route path="/report" element={<Report />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
