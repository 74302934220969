import { useNavigate } from "react-router-dom";
import { removeCookie } from "../../utils/auth";
import { routes } from "../../utils/constants/route";

export const useSidebarLogic = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    removeCookie();
    navigate("/", {
      replace: true,
    });
  };

  let navItems = routes.USER;

  return { navItems, handleLogout };
};
