import AssessmentIcon from "@mui/icons-material/Assessment";

export const routes = {
  USER: [
    {
      id: "report",
      name: "Report",
      Icon: AssessmentIcon,
    },
  ],
};
