import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { usePost } from "../hooks/usePost";
import { useFetch } from "../hooks/useFetch";
import { useUserContext } from "../context/context";

export default function useReportLogic() {
  const [tableData, setData] = useState([]);
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "msisdn",
      label: "Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "response",
      label: "Response",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "time_created",
      label: "Timestamp",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const result = useFetch("report", true);
  useEffect(() => {
    if (result.status === "success") {
      setData(result.data.data?.data);
    }
  }, [result.status]);

  const [open, setOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const { to, from } = useUserContext();

  const queryClient = useQueryClient();

  const onErrorCall = (error) => {
    console.log({ error });
    setNotificationMessage(error?.response?.data?.data || error.message);
    setData(result.data.data?.data);
  };

  const onSuccessFulCall = (data) => {
    queryClient.setQueryData("reportData", data, {});
    console.log({ resp: data.data.data });
    setData(data.data?.data);
  };

  const { mutate, isLoading, isError, data, error } = usePost(
    onSuccessFulCall,
    onErrorCall
  );
  const onSubmit = (event, _event) => {
    event.preventDefault();
    const loginData = {
      from: from.format("YYYY-MM-DD"),
      to: to.format("YYYY-MM-DD"),
    };
    mutate({ url: `report`, data: loginData, method: "post" });
  };

  //would like to come back to this and figure it out
  //why isloading is always true though my app has returned a response
  useEffect(() => {
    console.log({ isLoading });
  });

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return {
    columns,
    tableData,
    state: {
      open,
      notificationMessage,
    },
    handlers: { handleClose, onSubmit },
    fetchResponse: {
      isLoading,
      isError,
      data,
      error,
    },
  };
}
