import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import useReportLogic from "./useReportLogic";
import { CircularProgress, Grid } from "@mui/material";

import { Button } from "@mui/material";
import Notification from "../components/Notification";
import { green } from "@mui/material/colors";
import { Box } from "@mui/material";
import { useUserContext } from "../context/context";

function Report() {
  const { columns, tableData, state, fetchResponse, handlers } =
    useReportLogic();
  const { open, notificationMessage } = state;
  const { handleClose, onSubmit } = handlers;

  const { handleToDateChange, handleFromDateChange, from, to } =
    useUserContext();

  const options = {
    selectableRows: "none",
    responsive: "standard",
    search: true,
    filter: false,
    download: true,
    viewColumns: false,
  };

  return (
    <div style={{ margin: "20px" }}>
      <h1>Report page</h1>
      <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
        <Grid container spacing={2} alignItems="center" marginBottom={10}>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Starting Date"
                inputFormat="MM/DD/YYYY"
                value={from}
                onChange={handleFromDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Ending Date"
                inputFormat="MM/DD/YYYY"
                value={to}
                onChange={handleToDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 3, height: 50 }}
              disabled={fetchResponse.isLoading}
            >
              Search
              {fetchResponse.isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "52.2%",
                    left: "49%",
                  }}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <MUIDataTable
        headStyle={{ backgroundColor: "red" }}
        title={"List of Unsubscribed Numbers"}
        data={tableData}
        columns={columns}
        options={options}
        theme={{
          MUIDataTable: {
            headerCell: {
              backgroundColor: "red",
              margin: "90px",
            },
          },
        }}
      />

      <Notification
        severity="error"
        message={notificationMessage}
        openNotification={open}
        handleClose={handleClose}
      />
    </div>
  );
}

export default Report;
